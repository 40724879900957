<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ item && item.id ? $t("labels.edit") : $t("labels.add") }}
      <v-spacer></v-spacer>
      <v-btn color="success" @click="save" class="mr-2">
        {{ $t("labels.save") }}
      </v-btn>
      <v-btn color="error" outlined @click="close">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            v-model="item.name"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="$t('labels.dc_distributor')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.phone"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="$t('labels.dc_phone_account')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.password"
            type="password"
            class="c-input-small"
            outlined
            dense
            hide-details
            autocomplete="off"
            :label="$t('labels.password')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <SelectCity
            class="c-input-small"
            :id-item="item.id"
            :id-city="item.id_city"
            :label="$t('labels.city')"
            name="id_city"
            @onChange="onItemChange"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <SelectCounty
            class="c-input-small"
            :id-item="item.id"
            :id-city="item.id_city"
            :id-county="item.id_county"
            :label="$t('labels.district')"
            name="id_county"
            @onChange="onItemChange"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <SelectWard
            class="c-input-xs"
            :id-item="item.id"
            :id-city="item.id_city"
            :id-county="item.id_county"
            :id-ward="item.id_ward"
            :label="$t('labels.ward')"
            name="id_ward"
            @onChange="onItemChange"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.address"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="$t('labels.address')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "DistributorCreate",
  components: {
    SelectCity: () => import("@/components/common/SelectCity"),
    SelectCounty: () => import("@/components/common/SelectCounty"),
    SelectWard: () => import("@/components/common/SelectWard"),
  },
  props: {
    selectItem: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    item: {
      name: null,
      phone: null,
      id_city: null,
      id_county: null,
      id_ward: null,
      address: null,
    },
  }),
  computed: {},
  created() {
    this.resetInput();
  },
  mounted() {},
  methods: {
    onItemChange(val) {
      this.item = { ...this.item, [val.name]: val.value };
    },

    close() {
      this.$emit("close", true);
    },
    resetInput() {
      const item = {
        name: null,
        phone: null,
        id_city: null,
        id_county: null,
        id_ward: null,
        address: null,
      };
      this.item = { ...item };
      if (this.selectItem && this.selectItem.id) {
        this.item = { ...this.item, ...this.selectItem };
      }
    },

    async save() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/dc-distributor-save", this.item);
        this.isLoading = false;
        const msg = this.item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
        this.close();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
